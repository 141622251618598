/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { graphql } from 'gatsby'
import { Hero, AboutMe, Container, Slider, ContactForm } from '@components'
import { useProducts } from '@hooks'
import { ImageType, MediaType } from '@types'

import { DefaultLayout } from '../layouts/defaultlayout'

export const query = graphql`
  query {
    hero: directus {
      ...HeroFragment
    }
    aboutme: directus {
      ...AboutMeFragment
    }
    contact: directus {
      ...ContactFragment
    }
  }
`

interface Props {
  data: {
    aboutme: {
      aboutme: {
        title: string
        subtitle: string
        text: string
        image_text: string
        image: ImageType
      }
    }
    hero: {
      herosection: {
        video: MediaType
        logo_desktop: MediaType
        logo_mobile: MediaType
      }
    }
    contact: {
      contact: {
        title: string
        subtitle: string
      }
    }
  }
}

// eslint-disable-next-line import/no-default-export
export default ({ data }: Props) => {
  const { aboutme, hero, contact } = data
  const [products, isProductsLoaded] = useProducts()
  return (
    <DefaultLayout
      isDataLoaded={isProductsLoaded}
      heroElement={<Hero isDataLoaded={isProductsLoaded} {...hero.herosection} anchor="navigation" />}
    >
      <Container>
        <AboutMe {...aboutme.aboutme} />
      </Container>
      <Slider products={products} isProductsLoaded={isProductsLoaded} />
      <Container>
        <ContactForm {...contact.contact} />
      </Container>
    </DefaultLayout>
  )
}
